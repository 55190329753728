export default {
  auth: {
    resetPasswordLink: {
      forgotPassword: "Passwort vergessen?",
    },
  },
  cards: {
    needLoginCard: {
      needLogged: "Um diesen Inhalt zu sehen, müssen Sie angemeldet sein.",
    },
    productHomeCard: {
      inceptionToDateReturn: "Rendite seit Auflegung",
      lastDay: "Änderung am letzten Tag",
      lastPrice: "Letzter Preis",
      na: "N/V",
      viewDetails: "Details anzeigen",
      yearToDateReturn: "Rendite seit Jahresbeginn",
    },
  },
  compliance: {
    cookies: {
      buttonText: "Akzeptieren",
      cookiesUsage:
        "Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu verbessern. Durch die Nutzung dieser Website erklären Sie sich mit der Verwendung von Cookies sowie mit der Datenschutzerklärung einverstanden, in der Sie weitere Informationen über Cookies finden. Link: {0}.",
      privacyPolicy: "Datenschutzerklärung",
    },
  },
  components: {
    downloadAsPngTooltip: "Als PNG herunterladen",
    details: {
      aumFormat: (ctx) =>
        `${ctx.named("currency")} ${(ctx.named("aum") / 1000000).toFixed(
          1
        )} Million${ctx.named("aum") > 1000000 ? "en" : ""}${
          ctx.named("asOfDate") ? ` (Stand ${ctx.named("asOfDate")})` : ""
        }`,
    },
  },
  datePickers: {
    datePicker: {
      selectDateTooltip: "Datum auswählen",
    },
    dateRangePicker: {
      from: "Von (JJJJ-MM-TT)",
      fullPeriod: "Gesamter Zeitraum",
      to: "Bis (JJJJ-MM-TT)",
    },
    zoomPicker: {
      fiveYears: "5J",
      fullPeriod: "MAX",
      oneMonth: "1M",
      oneYear: "1J",
      sixMonths: "6M",
      threeMonths: "3M",
      tenYears: "10J",
      threeYears: "3J",
      twoYears: "2J",
      ytd: "YTD",
    },
  },
  dropdowns: {
    iconsDropdown: {
      noIcon: "Kein Symbol",
    },
  },
  editPrompt: {
    copyInAllLangs: "Kopieren in allen Sprachen",
    editField: "Feld bearbeiten",
    enterText: "Text eingeben",
    overwriteDataAllShareClasses: "Daten aller Anteilsklassen überschreiben",
    id: "ID",
    idTooltip:
      "Dieser eindeutige Bezeichner dient als Referenz für die Komponente innerhalb der Plattform. Jede Komponente, die Sie erstellen, benötigt einen eigenen Bezeichner. Zur besseren Lesbarkeit empfehlen wir die folgende Konvention: Verwenden Sie den Komponententitel, konvertieren Sie ihn in Kleinbuchstaben und ersetzen Sie Leerzeichen durch Bindestriche. Für eine Komponente mit dem Titel 'My Analytic Table' würde der Bezeichner 'my-analytic-table' lauten.",
    needUniqueId:
      "Der angegebene Bezeichner wird derzeit von einer anderen Komponente am aktuellen Standort oder an einem verknüpften Standort verwendet. Bitte geben Sie einen eindeutigen Bezeichner an, um die Eindeutigkeit zu gewährleisten.",
    numberOfDecimals: "Anzahl der Nachkommastellen",
    numberOfDecimalsTooltip:
      "Die Anzahl der Nachkommastellen für alle in der Komponente angezeigten Zahlen. Beachten Sie, dass dies nicht für Diagrammachsen gilt.",
    withBenchmark: "Mit dem Benchmark anzeigen",
    footnote: "Text unter der Komponente",
    placeholderFootnote:
      "Ein Text, der unterhalb der Komponente erscheint. Z. B. 'Gebühren sind inbegriffen.'",
    langTabError: "Einige Felder sind in den folgenden Sprachen ungültig:",
    settingsOnlyUsedOnOverviewTooltip:
      "Diese Einstellung betrifft ausschließlich die Seite 'Übersicht'.",
    showOnFactsheet: "Auf Factsheet anzeigen",
    tabError: "Einige Felder sind ungültig",
    title: "Titel",
    titleEdit: (ctx) => `${ctx.named("componentId")} bearbeiten`,
    titleAdd: "Komponente hinzufügen",
    unsavedChangesAlert:
      "Sie haben ungespeicherte Änderungen. Wenn Sie nicht speichern, gehen sie verloren.",
    factsheetFooter: {
      title: "Fußzeile",
      text: "Text",
      saveSuccess: "Fußzeile wurde erfolgreich aktualisiert",
      showPageNumber: "Seitenzahl anzeigen",
      showFooterLine: "Fußzeile anzeigen",
    },
    factsheetHeader: {
      backgroundImage: "Hintergrundbild",
      hideProductName: "Produktname ausblenden",
      logo: "Logo",
      onlyOnFirstPage: "Nur auf erster Seite",
      saveSuccess: "Kopfzeile wurde erfolgreich aktualisiert",
      textInPrimary: "Text in Markenfarbe",
      title: "Header",
      useCompanyLogo: "Firmenlogo verwenden",
      datePosition: {
        bottomLeft: "Unten links",
        bottomRight: "Unten rechts",
        standard: "Standard",
        title: "Datumsposition",
        topLeft: "Oben links",
        topRight: "Oben rechts",
      },
    },
    factsheetStyle: {
      primaryColor: "Hauptfarbe",
      saveSuccess: "Stil wurde erfolgreich aktualisiert",
      title: "Stil",
      fontSize: {
        normal: "Normal",
        large: "Gross",
        small: "Klein",
        title: "Schriftgrösse",
      },
      spacing: {
        comfortable: "Bequem",
        compact: "Kompakt",
        title: "Abstände",
      },
      titleBorder: {
        above: "Oberhalb dem Titel",
        below: "Unter dem Titel",
        none: "Keine Grenze",
        title: "Titel Grenze",
      },
    },
    genericComponent: {
      addColumn: "Spalte hinzufügen",
      addRow: "Reihe hinzufügen",
      addData: "Daten hinzufügen",
      barChart: "Balkendiagramm",
      cantChangeType:
        "Sie können den Typ einer generische Komponente nicht ändern, nachdem Sie sie gespeichert haben. Wenn Sie einen anderen Typ möchten, müssen Sie eine neue generische Komponente erstellen.",
      columnWithIndex: (ctx) => `Spalte ${ctx.named("idx")}`,
      data: "Daten",
      date: "Datum",
      donutChart: "Donutdiagramm",
      exampleSector: "Z.B., Sektoren",
      header: "Kopfzeile",
      key: "Schlüssel",
      image: "Bild",
      lineChart: "Liniendiagramm",
      needData:
        "Sie können eine generische Komponente nicht ohne Daten oder nur mit teilweisen Daten speichern. Löschen Sie sie, fügen Sie Daten hinzu oder vervollständigen Sie vorhandene Daten vor dem Speichern.",
      pieChart: "Kreisdiagramm",
      removeData: "Daten entfernen",
      showColumnHeader: "Spaltenüberschrift anzeigen",
      table: "Tabelle",
      text: "Text",
      type: "Typ",
      useBrandColor: "Markenfarbe verwenden",
      value: "Wert",
      video: "Video",
      weightPercent: "Gewicht (in %)",
    },
    contacts: {
      addContact: "Kontakt hinzufügen",
      additionalText: "Zusätzlicher Text",
      email: "E-Mail",
      exampleEmail: "Z.B., jdoe@mail.com",
      exampleName: "Z.B., John Doe",
      examplePhone: "Z.B., +41 79 123 45 67",
      exampleRole: "Z.B., Partner",
      imageSquare: "Bild (quadratisch)",
      name: "Name",
      phone: "Telefon",
      removeContact: "Kontakt entfernen",
      role: "Rolle",
    },
    dialog: {
      copyInAllLangs: {
        title: "Kopieren in allen Sprachen",
        text: "Sie kopieren alle Werte der aktuellen Sprache in alle anderen Sprachen. Sind Sie sicher?",
      },
      saveFieldInAllShareClasses: {
        title: "Überschreiben",
        text: "Sie überschreiben dieses Feld in allen anderen Anteilsklassen dieses Produkts, nicht nur in der aktuellen Anteilsklasse. Sind Sie sicher?",
      },
      saveInAllShareClasses: {
        title: "Überschreiben",
        text: (ctx) =>
          `Sie überschreiben die Daten von "${ctx.named(
            "tabName"
          )}" in allen anderen Anteilsklassen dieses Produkts mit den Daten von "${ctx.named(
            "tabName"
          )}" dieser Anteilsklasse (nicht nur die letzten Änderungen, die Sie vorgenommen haben). Sind Sie sicher?`,
      },
    },
    description: {
      contentTitle: "Beschreibung",
    },
    disclaimer: {
      contentTitle: "Haftungsausschluss",
      defaultWarning:
        "Sie haben derzeit keinen Haftungsausschluss für dieses Produkt. Daher wurde der Standard-Haftungsausschluss verwendet. Wenn Sie hier einen Haftungsausschluss verfassen, wird dieser nur für dieses Produkt angezeigt und nicht der Standard-Haftungsausschluss.",
    },
    historicalPricesChart: {
      defaultLogScale: "Standardmäßig in LogScale",
      showLogScaleSwitch: "Schalter für LogScale anzeigen",
      priceIndexedTo100: "Preisindexierung auf 100",
    },
    holdingOverTime: {
      elementsInOthers: {
        title: 'Elemente, die als "Andere" zu betrachten sind',
        multiSelect: {
          placeholder: "Elemente auswählen",
          selectLabel: "Enter drücken zum Auswählen",
          deselectLabel: "Enter drücken, um zu entfernen",
          selectedLabel: "Ausgewählt",
          limitText: (ctx) => `und ${ctx.named("count")} weitere`,
        },
      },
    },
    keyCharacteristics: {
      addKeyCharacteristics: "Schlüsselmerkmale hinzufügen",
      exampleDynamicExposure: "z. B., Dynamische Exposition",
      exampleWeDynamicallyAdapt: "z. B., Wir passen uns dynamisch an ...",
      header: "Überschrift",
      icon: "Symbol",
      paragraph: "Absatz",
      removeKeyCharacteristics: "Hauptmerkmale entfernen",
    },
    monthlyReturnsChart: {
      startDate: "Anfangsdatum",
      startDateTooltip:
        "Wählen Sie ein Anfangsdatum (im Format JJJJ-MM-TT) für die Grafik. Die Grafik wird automatisch so angepasst, dass die Daten ab dem frühesten verfügbaren Datum für das Produkt oder dem von Ihnen gewählten Datum angezeigt werden, je nachdem, welches Datum später liegt. Wenn Sie dieses Feld leer lassen, werden alle verfügbaren Preisdaten für das Produkt angezeigt.",
    },
    monthlyReturnsTable: {
      descending: "Jahre in absteigender Reihenfolge sortieren",
      startDate: "Anfangsdatum",
      startDateTooltip:
        "Wählen Sie ein Anfangsdatum (im Format JJJJ-MM-TT) für die Rückgabetabelle. Die endgültige Tabelle wird automatisch so angepasst, dass die Daten ab dem frühesten verfügbaren Datum für das Produkt oder dem von Ihnen gewählten Datum angezeigt werden, je nachdem, welches Datum später liegt. Wenn Sie dieses Feld leer lassen, werden alle verfügbaren Preisdaten für das Produkt angezeigt.",
      benchmark: "Benchmark",
      product: "Produkt",
      return: "Rendite",
      nameReplacements: "Standardnamen ersetzen",
      nameReplacementsTooltip:
        "Passen Sie die Produkt- und Benchmark-Namen in der Komponente an. Lassen Sie das Feld leer, um die vorhandenen Namen zu übernehmen.",
    },
    notify: {
      componentSaveSuccess: (ctx) =>
        `Ihre Komponente '${ctx.named("componentId")}' wurde erfolgreich aktualisiert.`,
    },
    overviewHeader: {
      backgroundImage: "Hintergrundbild",
      backgroundImageTooltip:
        "Bitte beachten Sie, dass das aktuell betrachtete Bild auch als Produktkartenbild auf der Homepage verwendet wird. Änderungen an diesem Bild wirken sich daher gleichzeitig auf die Darstellung des Produkts auf der Homepage aus.",
      text: "Text",
      textTooltip:
        "Bitte beachten Sie, dass der aktuell betrachtete Text auch als Produktkartentext auf der Homepage verwendet wird. Änderungen an diesem Text wirken sich daher gleichzeitig auf die Darstellung des Produkts auf der Homepage aus.",
      showDownloadLastFactsheetButton:
        'Schaltfläche "@:components.extras.downloadLastFactsheet.downloadLastFactsheet" anzeigen',
      showDownloadLastFactsheetButtonTooltip:
        'Damit die Schaltfläche für einen Benutzer sichtbar ist, sollte ein Dokument als "@:pages.products.documents.prompt.uploadFile.shouldConsiderLastFactsheet" im Abschnitt "Dokumente" markiert sein, und es sollte in der vom Benutzer gewählten Sprache verfügbar sein.',
      saveSuccess: "Kopfzeile wurde erfolgreich aktualisiert.",
      showFollowButton: "Schaltfläche zum Folgen anzeigen",
      title: "Übersichtskopf bearbeiten",
    },
    predefinedHoldingComponent: {
      chartType: "Diagrammtyp",
      barChart: "Balkendiagramm",
      donutChart: "Donutdiagramm",
      pieChart: "Kreisdiagramm",
      table: "Tabelle",
      useBrandColor: "Markenfarbe verwenden",
      assetClasses: {
        editAssetClasses: "Assetklassen bearbeiten",
      },
      currencyExposure: {
        editCurrencyExposure: "Währungsexposure bearbeiten",
      },
      instrumentTypes: {
        editInstrumentTypes: "Instrumententypen bearbeiten",
      },
      onlyShowTopThenOthers: {
        tooltip:
          'Nur das oberste X (gewählte Zahl) anzeigen, und die übrigen in "Andere" zusammenfassen. Leer lassen, um alle anzuzeigen.',
      },
      regions: {
        editRegions: "Regionen bearbeiten",
      },
      sectors: {
        editSectors: "Sektoren bearbeiten",
      },
    },
    productDetails: {
      addDetail: "Detail hinzufügen",
      exampleIsin: "Z.B., ISIN",
      exampleLU: "Z.B., LU2220388479",
      key: "Schlüssel",
      noteDragAndDrop:
        "Hinweis: Durch Ziehen und Ablegen von Zeilen können Sie die Reihenfolge der Details ändern.",
      removeDetail: "Detail entfernen",
      showAuM: "AuM anzeigen",
      showLastPrice: "Letzten Preis anzeigen",
      value: "Wert",
    },
    relativePerformanceChart: {
      footnote: "Fussnote",
      placeholderFootnote:
        "Ein Text, der unterhalb des Diagramms erscheint. Z.B. 'Gebühren sind enthalten.'",
    },
    riskProfile: {
      level: "Höhe des Risikos",
    },
    shareClassesTable: {
      bloomberg: "Bloomberg",
      class: "Klasse",
      defaultFootnote:
        "Perf. Lanc.: Die Renditen für Zeiträume seit Auflegung von mehr als einem Jahr werden annualisiert, bei weniger als einem Jahr werden sie nicht annualisiert.",
      footnote: "Fussnote",
      inceptionDate: "Lancierungsdatum",
      isin: "ISIN",
      mgmtFee: "Mgmt. fee",
      perfFee: "Perf. fee",
      title: "Tabelle der Anteilsklassen bearbeiten",
    },
    statistics: {
      benchmark: "Benchmark",
      product: "Produkt",
      nameReplacements: "Standardnamen ersetzen",
      nameReplacementsTooltip:
        "Passen Sie die Produkt- und Benchmark-Namen in der Komponente an. Lassen Sie das Feld leer, um die vorhandenen Namen zu übernehmen. Verwenden Sie z.B. einen Begriff wie 'Produkt', um den Namen abzukürzen und Platz zu sparen.",
      fieldsToDisplay: "Anzuzeigende Felder",
      noteDragAndDrop:
        "Hinweis: Durch Ziehen und Ablegen von Zeilen können Sie die angezeigten Statistiken neu anordnen.",
      shownStatisticName: "Angezeigter Statistikname",
      showStatistic: "Statistik anzeigen",
      statisticId: "Statistik-ID",
    },
    statisticsWithPeriods: {
      benchmark: "Benchmark",
      product: "Produkt",
      nameReplacements: "Standardnamen ersetzen",
      nameReplacementsTooltip:
        "Passen Sie die Produkt- und Benchmark-Namen in der Komponente an. Lassen Sie das Feld leer, um die vorhandenen Namen zu übernehmen. Verwenden Sie z.B. einen Begriff wie 'Produkt', um den Namen abzukürzen und Platz zu sparen.",
      fieldsToDisplay: "Anzuzeigende Felder",
      noteDragAndDrop:
        "Hinweis: Durch Ziehen und Ablegen von Zeilen können Sie die angezeigten Statistiken neu anordnen.",
      periodsTitle: "Anzuzeigende Zeiträume",
      shownStatisticName: "Angezeigter Statistikname",
      showStatistic: "Statistik anzeigen",
      statisticId: "Statistik-ID",
      periods: {
        1: "1M",
        3: "3M",
        6: "6M",
        ytd: "YTD",
        12: "1J",
        36: "3J",
        60: "5J",
        max: "MAX",
      },
    },
    topHoldings: {
      fieldsToDisplay: "Anzuzeigende Felder",
      showHeader: "Kopfzeile anzeigen",
      showTotal: "Summe anzeigen",
      numHoldings: {
        title: "Anzahl der anzuzeigenden Positionen",
        tooltip:
          "Wenn Sie dieses Feld leer lassen, werden alle verfügbaren Daten zu den Beständen des Produkts angezeigt.",
      },
      holdingsToIgnore: {
        title: "Zu ignorierende Holdings",
        holdingsMultiselect: {
          placeholder: "Zu ignorierende Holdings auswählen",
          selectLabel: "Enter drücken zum Auswählen",
          deselectLabel: "Enter drücken, um zu entfernen",
          selectedLabel: "Ausgewählt",
          limitText: (ctx) => `und ${ctx.named("count")} weitere`,
        },
      },
      customizeTable: "Tabelle anpassen",
      addColumn: "Spalte hinzufügen",
      columnWithIndex: (ctx) => `Spalte ${ctx.named("idx")}`,
    },
  },
  extras: {
    changeRoles: {
      changeRoles: "Rollen ändern",
      notify: {
        errorText: "Keine Rollen wurden aktualisiert. Grund: ",
        successText: "Benutzerrollen wurden aktualisiert",
      },
    },
    deleteGenericComponent: {
      delete: "Löschen",
      deleteAllComponents: "Alle Komponenten löschen",
      deleteOnlyThisComponent: "Nur diese Komponente löschen",
      Löschen: "Löschen von ...",
      deleteSuccess: "Löschung erfolgreich.",
      page: "Seite",
      shareClass: "Aktienklasse",
      textIsNotLinked:
        "Sie sind im Begriff, eine generische Komponente dauerhaft zu löschen. Diese Aktion kann nicht rückgängig gemacht werden. Bitte bestätigen Sie, dass Sie mit der Löschung fortfahren möchten.",
      textIsLinkedFirstPart:
        "Sie sind im Begriff, eine generische Komponente dauerhaft zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.  Beachten Sie, dass diese Komponente mit der gleichen Komponente(n) im Folgenden verknüpft ist: ",
      textIsLinkedSecondPart:
        "Bitte bestätigen Sie, dass Sie mit dem Löschen fortfahren möchten, und entscheiden Sie, ob Sie nur die aktuelle Komponente löschen möchten, oder ob Sie diese Komponente und alle verknüpften Komponenten löschen möchten.",
      title: (ctx) => `Dauerhafte Löschung von ${ctx.named("componentId")}`,
      pageList: {
        factsheet: "Factsheet",
        overview: "Übersicht",
      },
    },
    downloadLastFactsheet: {
      downloadLastFactsheet: "Letztes Factsheet herunterladen",
      downloadingLastFactsheet: "Downloading...",
      needLogin: {
        text: "Um dieses Dokument herunterladen zu können, müssen Sie authentifiziert sein.",
        title: "Sie müssen authentifiziert sein",
      },
    },
    manualVerifyEmail: {
      forceVerification: "E-Mail-Verifizierung erzwingen",
      verified: "E-Mail verifiziert",
      notify: {
        errorText: "E-Mail-Verifizierung ist fehlgeschlagen.",
        successText: "Die E-Mail des Benutzers wurde manuell verifiziert.",
      },
    },
    riskProfile: {
      higherRisk: "Höheres Risiko",
      lowerRisk: "Niedrigeres Risiko",
      potentiallyHigherReward: "Potenziell höhere Rendite",
      potentiallyLowerReward: "Potenziell niedrigere Rendite",
    },
  },
  factsheet: { lastPrice: "Letzter Preis" },
  graphs: {
    logScale: "Logarithmische Skala",
    notEnoughData:
      "Nicht genügend Daten, um dieses Diagramm zu erstellen. Bitte wählen Sie einen längeren Zeitraum.",
    needMoreData:
      "Um diese Kennzahl zu berechnen, ist eine bestimmte Menge an Daten erforderlich. Vergrößern Sie den Zeitrahmen, um Zugang zu dieser Kennzahl zu erhalten.",
  },
  holdingData: {
    others: "Andere",
  },
  images: {
    uploadImage: {
      darkening: "Verdunkelung",
      file: "Datei",
      noFile: "Keine Datei",
      noImage: "Kein Bild",
      selectFile: "Datei auswählen",
      selectNewImage: "Neues Bild auswählen",
      errorTitleTooLarge: "Bild zu groß",
      errorMessageTooLarge:
        "Das Bild ist zu groß. Bitte wählen Sie ein anderes Bild mit einer Größe von weniger als 30 MB.",
      warningTitleVeryLarge: "Bild sehr groß",
      warningMessageVeryLarge:
        "Das Bild ist sehr groß. Dadurch werden die Seiten, die dieses Bild anzeigen, langsamer geladen. Für eine optimale Leistung wird empfohlen, Bilder mit einer Größe von weniger als 10 MB zu verwenden.",
    },
  },
  linkPrompt: {
    componentLinkedTo: "Diese Komponente ist verknüpft mit:",
    currentPage: "Aktuelle Seite",
    currentShareClass: "Aktuelle Aktienklasse",
    linkThroughPagesTooltip:
      'Wenn Sie diese Funktion aktivieren, wird eine synchronisierte Verbindung zwischen Komponenten auf mehreren Seiten hergestellt. Durch die Verknüpfung der Seiten "Factsheet" und "Übersicht" wird beispielsweise sichergestellt, dass alle Änderungen an einer gemeinsamen Komponente auf beiden Seiten gleichzeitig aktualisiert werden. Wichtig: Der Inhalt der verknüpften Komponente wird von der Quellseite, die Sie gerade bearbeiten, dupliziert. Wenn Sie die Verknüpfung initiieren, während Sie die Komponente "Beschreibung" auf der Seite "Factsheet" bearbeiten, wird die Komponente "Beschreibung" von "Factsheet" auf die Seite "Übersicht" kopiert und überschreibt somit die Komponente "Beschreibung" von "Übersicht".',
    linkThroughShareClassesTooltip:
      'Wenn Sie diese Funktion aktivieren, wird eine synchronisierte Verbindung zwischen Komponenten über mehrere Freigabeklassen hinweg hergestellt. Durch die Verknüpfung der "Produkt Aktienklassen A" und der "Produkt Aktienklassen B" wird beispielsweise sichergestellt, dass alle Änderungen an einer freigegebenen Komponente in beiden Freigabeklassen gleichzeitig aktualisiert werden. Wichtig: Der Inhalt der verknüpften Komponente wird von der Quellseite dupliziert, die Sie gerade bearbeiten. Wenn Sie die Verknüpfung initiieren, während Sie die Komponente "Beschreibung" auf der Seite "Produkt Aktienklassen A" bearbeiten, wird die Komponente "Beschreibung" in "Produkt Aktienklassen A" in "Produkt Aktienklassen B" kopiert und überschreibt somit die Komponente "Beschreibung" in "Produkt Aktienklassen B".',
    linkedWithPages: "Verknüpft mit den folgenden Seiten",
    linkedWithShareClasses: "Verknüpft mit den folgenden Aktienklassen",
    page: "Seite",
    shareClass: "Aktienklasse",
    title: (ctx) => `Verknüpfung von ${ctx.named("componentId")} bearbeiten`,
    unlink: "Entbinden",
    unlinking: "Entbinden...",
    unsavedChangesAlert:
      "Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, ohne zu speichern, gehen sie verloren.",
    confirmSaveDialog: {
      computingNewLinks: "Berechnung neuer Links...",
      onceLinked:
        "Einmal verknüpft, werden alle anderen verknüpften Komponenten auf die gleiche Weise aktualisiert, wenn eine Änderung an einer Komponente an einem dieser Orte (einschließlich des aktuellen) vorgenommen wird. Bitte bestätigen Sie, dass Sie mit dem Speichern fortfahren möchten.",
      overwrittenExistingComponent: "Überschreiben einer Komponente mit der gleichen ID",
      overwrittenExistingComponentTooltip: (ctx) =>
        `Warnung: Es gibt bereits eine Komponente mit der ID '${ctx.named(
          "componentId"
        )}' an dieser Stelle, so dass sie von dieser Komponente überschrieben wird, wenn Sie sie verlinken.`,
      title: "Bestätigen Sie die Speicherung der neuen Links",
      youAreAboutToLink:
        "Sie sind dabei, die aktuelle Komponente mit den Komponenten an den folgenden Stellen zu verknüpfen:",
    },
    factsheetFooter: {
      title: "Links in der Fusszeile des Factsheets bearbeiten",
      saveSuccess: "Die Aktualisierung der Fusszeilenlinks war erfolgreich.",
    },
    factsheetHeader: {
      title: "Links in der Kopfzeile des Factsheets bearbeiten",
      saveSuccess: "Die Aktualisierung der Kopfzeilenlinks war erfolgreich.",
    },
    factsheetStyle: {
      title: "Links im Stil des Factsheets bearbeiten",
      saveSuccess: "Die Aktualisierung der Fusszeilenlinks war erfolgreich.",
    },
    notify: {
      linkSaveSuccess: (ctx) =>
        `Die Verknüpfungsaktualisierung von '${ctx.named("componentId")}' war erfolgreich.`,
    },
    multiselect: {
      Platzhalter: "Zu verlinkende Seiten auswählen",
      selectLabel: "Zum Auswählen die Eingabetaste drücken",
      deselectLabel: "Zum Entfernen die Eingabetaste drücken",
      selectedLabel: "Ausgewählt",
    },
    overviewHeader: {
      title: "Verknüpfung des Übersichtskopfes bearbeiten",
      saveSuccess: "Die Aktualisierung der Verknüpfung des Kopfes war erfolgreich.",
    },
    pageList: {
      factsheet: "Factsheet",
      overview: "Übersicht",
      analysis: "Analyse",
    },
  },
  pricesManagement: {
    addData: "Daten hinzufügen",
    autoDataUpdate: "Ihre Daten werden automatisch aktualisiert.",
    date: "Datum",
    datePlaceholder: "JJJJ-MM-TT",
    dateValidationError: "Sie können kein Datum hinzufügen, das bereits einen Wert hat.",
    exportCsv: "Als CSV exportieren",
    importCsv: "Aus einer CSV importieren",
    importCsvFormat:
      "Die Eingabedatei sollte im CSV-Format vorliegen, mit zwei Spalten und einer Kopfzeile. Die erste Spalte sollte aus Datumsangaben und die zweite Spalte aus Zahlen bestehen (der Preis zum entsprechenden Datum). Bitte stellen Sie sicher, dass Ihre CSV-Datei gültig ist, um Probleme beim Lesen und Parsen der Daten zu vermeiden.",
    loadingData: "Daten werden geladen...",
    price: "Preis",
    removeData: "Daten entfernen",
    benchmarks: {
      noBenchmark: "Für dieses Produkt ist kein Benchmark verfügbar.",
    },
    dialog: {
      removeData: {
        title: "Datenpunkt entfernen",
        text: "Sie werden einen Datenpunkt vollständig entfernen. Sind Sie sicher?",
      },
    },
    notify: {
      dateUsedMultipleTimeError:
        "Einem Datum sind mehrere Preise zugeordnet. Sie müssen sicherstellen, dass jedem Datum ein eindeutiger Preis zugewiesen wird.",
      numberFormatError:
        'Mindestens eine der Zahlen hat ein unerkanntes Format. Idealerweise sollten Sie keine Trennzeichen für Tausender verwenden und einen Punkt als Dezimaltrennzeichen einsetzen, da dies das erwartete Format ist. Zum Beispiel "102,12", "1380,25", "18,952", ...',
      saveErrorText: "Fehler beim Aktualisieren der Preise, bitte kontaktieren Sie uns.",
      saveSuccessText: "Die Preise wurden erfolgreich aktualisiert.",
    },
    prompt: {
      addPrice: "Preis hinzufügen",
      importCsv: "Daten aus CSV importieren",
    },
  },
  tables: {
    metricsTable: {
      na: "N/V",
      name: "Name",
      showLess: "Weniger anzeigen",
      showMore: "Mehr anzeigen",
    },
    monthlyReturnsTable: {
      benchmark: "Benchmark",
      return: "Rendite",
    },
    performanceTable: {
      computedFromTo: (ctx) =>
        `Performance berechnet von ${ctx.named("firstDate")} bis ${ctx.named("lastDate")}.`,
      notAvailableData:
        "Daten nicht verfügbar, entweder weil nicht genügend Tage zur Berechnung vorhanden sind, die Daten nicht aussagekräftig sind (z.B. negative Sharpe-Ratio) oder der Benchmark im ausgewählten Zeitraum nicht immer verfügbar ist",
    },
    topHoldingsTable: {
      holdingFields: {
        assetClass: "Anlageklasse",
        currency: "Währung",
        holdingName: "Name",
        instrumentType: "Instrumententyp",
        isin: "ISIN",
        region: "Region",
        sector: "Sektor",
        value: "Wert",
        weight: "Gewicht",
      },
      totalOfPortfolio: "Gesamtportfolio",
    },
  },
  thirdParty: {
    vuePhoneNumberInput: {
      countrySelectorLabel: "Länderauswahl",
      countrySelectorError: "Wählen Sie ein Land",
      phoneNumberLabel: "Telefonnummer",
      example: "Beispiel :",
    },
  },
  videos: {
    uploadVideo: {
      file: "Datei",
      noFile: "Keine Datei",
      noVideo: "Kein Video",
      selectFile: "Datei auswählen",
      selectNewVideo: "Neues Video auswählen",
      errorTitleTooLarge: "Video zu groß",
      errorMessageTooLarge:
        "Das Video ist zu groß. Bitte wählen Sie ein anderes Video mit einer Größe von weniger als 30 MB.",
      warningTitleVeryLarge: "Video sehr groß",
      warningMessageVeryLarge:
        "Das Video ist sehr groß. Dadurch werden die Seiten, die dieses Video anzeigen, langsamer geladen. Für eine optimale Leistung wird empfohlen, Videos mit einer Größe von weniger als 10 MB zu verwenden.",
    },
  },
};
