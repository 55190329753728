import _ from "lodash";

import axios from "@/http/axios";
import productsModule from "@/store/modules/products";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  useCaseConstituents: undefined,
  useCaseBenchmarks: undefined,
  useCaseDetails: undefined,
  useCaseCumulativeReturns: undefined,
  useCasePerformanceData: undefined,
  useCaseDrawdowns: undefined,
  useCaseBiggestDrawdowns: undefined,
};

const getters = {
  ...productsModule.getters,
  getUseCaseConstituents: (state) => {
    return state.useCaseConstituents;
  },
  getUseCaseBenchmarks: (state) => {
    return state.useCaseBenchmarks;
  },
  getUseCaseDetails: (state) => {
    return state.useCaseDetails;
  },
  getUseCaseCumulativeReturns: (state) => {
    return state.useCaseCumulativeReturns;
  },
  getUseCasePerformanceData: (state) => {
    return state.useCasePerformanceData;
  },
  getUseCaseDrawdowns: (state) => {
    return state.useCaseDrawdowns;
  },
  getUseCaseBiggestDrawdowns: (state) => {
    return state.useCaseBiggestDrawdowns;
  },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_USE_CASE_CONSTITUENTS_SUCCESS: (state, { data }) => {
    state.useCaseConstituents = data;
  },
  FETCH_USE_CASE_BENCHMARKS_SUCCESS: (state, { data }) => {
    state.useCaseBenchmarks = data;
  },
  FETCH_USE_CASE_DETAILS_SUCCESS: (state, { data }) => {
    state.useCaseDetails = data;
  },
  FETCH_USE_CASE_CUMULATIVE_RETURNS_SUCCESS: (state, { data }) => {
    state.useCaseCumulativeReturns = data;
  },
  FETCH_USE_CASE_PERFORMANCE_DATA_SUCCESS: (state, { data }) => {
    state.useCasePerformanceData = data;
  },
  FETCH_USE_CASE_DRAWDOWNS_SUCCESS: (state, { data }) => {
    state.useCaseDrawdowns = data;
  },
  FETCH_USE_CASE_BIGGEST_DRAWDOWNS_SUCCESS: (state, { data }) => {
    state.useCaseBiggestDrawdowns = data;
  },
};

const actions = {
  ...productsModule.actions,
  async fetchUseCaseConstituents({ commit }, { productId }) {
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/constituents`);
      commit("FETCH_USE_CASE_CONSTITUENTS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_CONSTITUENTS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseBenchmarks({ commit }, { productId }) {
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/benchmarks`);
      commit("FETCH_USE_CASE_BENCHMARKS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_BENCHMARKS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseDetails({ commit }, { productId }) {
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/details`);
      commit("FETCH_USE_CASE_DETAILS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_DETAILS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseCumulativeReturns(
    { commit },
    { productId, period, benchmark, constituentsWeights }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (constituentsWeights !== undefined) {
      params["constituents_weights"] = constituentsWeights;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/cumulative-returns`, {
        params: params,
      });
      commit("FETCH_USE_CASE_CUMULATIVE_RETURNS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_CUMULATIVE_RETURNS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCasePerformanceData(
    { commit },
    { productId, period, benchmark, constituentsWeights, statIds }
  ) {
    const params = {
      period_start_date: period.start,
      period_end_date: period.end,
      benchmark_symbol: benchmark,
      constituents_weights: constituentsWeights,
      stat_ids: statIds,
    };
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/performance-data`, {
        params: params,
      });
      commit("FETCH_USE_CASE_PERFORMANCE_DATA_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_PERFORMANCE_DATA_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseDrawdowns({ commit }, { productId, period, benchmark, constituentsWeights }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (constituentsWeights !== undefined) {
      params["constituents_weights"] = constituentsWeights;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/drawdowns`, {
        params: params,
      });
      commit("FETCH_USE_CASE_DRAWDOWNS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_DRAWDOWNS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseBiggestDrawdowns(
    { commit },
    { productId, period, benchmark, constituentsWeights }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (benchmark !== undefined) {
      params["benchmark_symbol"] = benchmark;
    }
    if (constituentsWeights !== undefined) {
      params["constituents_weights"] = constituentsWeights;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/use-case/biggest-drawdowns`, {
        params: params,
      });
      commit("FETCH_USE_CASE_BIGGEST_DRAWDOWNS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_BIGGEST_DRAWDOWNS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
